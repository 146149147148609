import React, { Suspense } from "react";
import "./App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import LeftMenu from "./components/LeftMenu";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

// const apiUrl = "http://languagelearn.risingstaritservices.com/api/";
const apiUrl = "/api/";

function App() {
  const { t } = useTranslation();
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={()=><Login i18n={i18n} t={t}></Login>} />
          <Route path="/homepage" component={()=><LeftMenu i18n={i18n} t={t}></LeftMenu>} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
export { apiUrl };
