import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../App";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
// import { translate, Trans } from "react-i18next";
import PropTypes from "prop-types";
//import Collapse from "@material-ui/core/Collapse";
//import Alert from "@material-ui/lab/Alert";
//import CloseIcon from "@material-ui/icons/Close";
//import IconButton from "@material-ui/core/IconButton";
import swal from "sweetalert";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Grid from "@material-ui/core/Grid";
//import Checkbox from "@material-ui/core/Checkbox";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
function Copyright(props) {
  const {t} = props;
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t("Copyright ©Compris.com")}{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const classes = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    //margin: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
    backgroundColor: "#f50057"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

class Login extends Component {
  state = {
    email: "", //"shahdeep1989@gmail.com",
    password: "", //"1234567",
    errorTextEmail: "",
    showErrorEmail: false,
    errorTextPassword: "",
    showErrorPassword: false,
    language: localStorage.getItem('lang') ? localStorage.getItem('lang'): 'de'
  };
  doLogin = () => {
    console.log(this.state);

    //window.open("#homepage", "_self");
    //return;

    if (this.state.email === "") {
      this.setState({
        errorTextEmail: "Email can't be blank",
        showErrorEmail: true
      });
    }

    if (this.state.password === "") {
      this.setState({
        errorTextPassword: "Password can't be blank",
        showErrorPassword: true
      });
    }

    if (this.state.email === "" || this.state.password === "") return;

    const loginData = {
      email: this.state.email,
      pass: this.state.password
    };
    axios
      .post(apiUrl + "login.php", loginData)
      .then(response => {
        if (response.data.statusText !== "success") {
          swal(this.props.t("Oops!"), this.props.t(response.data.message), "error");
        } else {
          localStorage.setItem("user_id", response.data.data.id);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("pass", response.data.data.pass);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("first_name", response.data.data.first_name);
          localStorage.setItem("last_name", response.data.data.last_name);
          localStorage.setItem("show_student", response.data.data.show_student);
          window.open("#homepage", "_self");
        }
      })
      .catch(error => {
        swal(this.props.t("Oops!"), this.props.t("Something went wrong."), "error");
      });
  };
  handleEmailChange = e => {
    this.setState({
      email: e.target.value,
      errorTextEmail: "",
      showErrorEmail: false
    });
  };

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
      errorTextPassword: "",
      showErrorPassword: false
    });
  };
  handleLanguageChange = e => {
    this.setState({language:e.target.value})
    localStorage.setItem("lang",e.target.value)
    this.props.i18n.changeLanguage(e.target.value);
  };
  render() {
    // const { t, i18n } = useTranslation();
    const { t } = this.props;
    return (
      <Container component="main" maxWidth="xs" className="loginContainer">
        <div className="loginSubContainer box-shadow">
          <CssBaseline />
          <div
            className={classes.paper}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "3em"
            }}
          >
            <Avatar
              className={classes.avatar}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                background: "#f50057"
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                textAlign: "center"
              }}
            >
              {t("Sign in")}
            </Typography>
            <form className={classes.form} noValidate>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                {t("Language")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Language"
                  value={this.state.language}
                  onChange={this.handleLanguageChange}
                >
                  <MenuItem value={"en"}>English</MenuItem>
                  <MenuItem value={"de"}>German</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={t('Email Address')}
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                required={true}
                error={this.state.showErrorEmail}
                helperText={t(this.state.errorTextEmail)}
                onChange={this.handleEmailChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={t('Password')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                required={true}
                error={this.state.showErrorPassword}
                helperText={t(this.state.errorTextPassword)}
                onChange={this.handlePasswordChange}
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Button
                //type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.doLogin}
              >
                {t('Sign In')}
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </form>
          </div>
          <Box mt={8}>
            <Copyright t={t}/>
          </Box>
        </div>
      </Container>
    );
  }

  /*
  render() {
    return (
      <React.Fragment>
        <Box mt={2}>
          <h3 className="textCenterCls">Sign In</h3>
        </Box>
        <Box mt={3}>
          <Container maxWidth="sm">
            <TextField
              id="emailFieldLogin"
              className="fieldCls"
              label="Email"
              type="email"
              value={this.state.email}
              required={true}
              error={this.state.showErrorEmail}
              helperText={this.state.errorTextEmail}
              onChange={this.handleEmailChange}
            />
            <TextField
              id="passwordFieldLogin"
              className="fieldCls"
              label="Password"
              type="password"
              value={this.state.password}
              required={true}
              error={this.state.showErrorPassword}
              helperText={this.state.errorTextPassword}
              onChange={this.handlePasswordChange}
            />
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                className="marginAutoItem"
                onClick={this.doLogin}
              >
                Login
              </Button>
            </Box>
          </Container>
        </Box>
      </React.Fragment>
    );
  }
  */
}

export default Login;
