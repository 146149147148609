import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../App";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import swal from "sweetalert";
import Loader from 'react-loader';
class CourseList extends Component {
  state = {
    couseList: [],
    apiCalled: false
  };

  componentDidMount = () => {
    console.log(this.props.i18n.language,'123')
    let authData = {
      user_id: localStorage.getItem("user_id"),
      token: localStorage.getItem("token")
    };

    axios
      .post(apiUrl + "authapis.php?action=getcourselist", authData)
      .then(response => {
        if (response.data.statusText !== "success") {
          swal("Oops!", response.data.message, "error");
          if (response.data.statusText === "unauthorised") {
            window.open("#", "_self");
          }
          this.setState({apiCalled:true})
        } else {
          let couseListArr = [];
          response.data.data.forEach(data => {
            couseListArr.push({
              key: data.id,
              name: this.props.i18n.language === 'en' ? data.name:data.name_de
            });
          });
          this.setState({
            couseList: couseListArr,
            apiCalled: true
          });
        }
      })
      .catch(error => {
        swal("Oops!", "Something went wrong.", "error");
      });
  };

  handleCourseClick = e => {
    console.log(e.target);
    console.log(e.target.innerText);
    console.log(e.target.closest(".listItemCls").id);
    let courseId = e.target.closest(".listItemCls").id.split("course")[1];
    this.props.courseClick(courseId, e.target.innerText);
  };

  render() {
    const listItems = this.state.couseList.map(couseObj => (
      <ListItem
        key={couseObj.key}
        className="listItemCls"
        id={"course" + couseObj.key}
        onClick={this.handleCourseClick}
      >
        <ListItemText primary={ couseObj.name} />
      </ListItem>
    ));
    const {t} = this.props;
    return (
      <Grid item xs={12}>
        <div>
        <Loader loaded={this.state.apiCalled}>
          {this.state.apiCalled && this.state.couseList.length <= 0 ? (
            <h3 className="textCenterCls marginTop4Em">{t("No course available.")}</h3>
          ) : (
            ""
          )}
          <List>{listItems}</List>
          </Loader>
        </div>
      </Grid>
    );
  }
}

export default CourseList;
