import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../App";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
const classes = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  }
}));
class Settings extends Component {
  state = {
    showStudent: localStorage.getItem("show_student") === "1" ? "1" : "0",
    language: localStorage.getItem('lang') ? localStorage.getItem('lang'): 'de'
  };

  showStudentChange = (e) => {
    console.log("Before: " + this.state.showStudent);
    this.setState({
      showStudent: this.state.showStudent === "1" ? "0" : "1",
    });

    setTimeout(() => {
      localStorage.setItem("show_student", this.state.showStudent);
      console.log(this.state.showStudent);

      let authData = {
        user_id: localStorage.getItem("user_id"),
        token: localStorage.getItem("token"),
        show_student: this.state.showStudent,
      };

      axios
        .post(apiUrl + "authapis.php?action=updateusersettings", authData)
        .then((response) => {
          if (response.data.statusText !== "success") {
            swal(this.props.t("Oops!"), this.props.t(response.data.message), "error");

            if (response.data.statusText === "unauthorised") {
              window.open("#", "_self");
            }
          } else {
            swal(this.props.t("Saved!"), this.props.t(response.data.message), "success");
          }
        })
        .catch((error) => {
          swal(this.props.t("Oops!"), this.props.t("Something went wrong."), "error");
        });
    }, 1000);
  };
  handleLanguageChange = e => {
    this.setState({language:e.target.value})
    localStorage.setItem("lang",e.target.value)
    this.props.i18n.changeLanguage(e.target.value);
  };
  render() {
    const {t} = this.props;
    return (
      <Grid item xs={12}>
        <div style={{ marginTop: "7em" }}>
          <Box mt={2}>
            <Container maxWidth="sm">
              <FormControlLabel
                value={this.state.showStudent}
                control={
                  <Switch
                    color="primary"
                    checked={this.state.showStudent === "1" ? true : false}
                  />
                }
                onChange={this.showStudentChange}
                label={t("Show Student Name")}
                labelPlacement="start"
              />
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                {t("Language")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Language"
                  value={this.state.language}
                  onChange={this.handleLanguageChange}
                >
                  <MenuItem value={"en"}>English</MenuItem>
                  <MenuItem value={"de"}>German</MenuItem>
                </Select>
              </FormControl>
            </Container>
          </Box>
        </div>
      </Grid>
    );
  }
}

export default Settings;
