import React, { Component } from "react";
import CourseList from "./CourseList";
import CreateInput from "./CreateInput";
import Settings from "./Settings";
import TeacherMainPage from "./TeacherMainPage";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SettingsIcon from "@material-ui/icons/Settings";

class LeftMenu extends Component {
  state = {
    isMenuOpen: false,
    addBtnText: "",
    showAddView: false,
    selectedCouse: "",
    // title: "Idioms & Phrases",
    // currentView: "CreateInput",
    title: "Course List",
    currentView: "CourseList",
  };
  componentDidMount(){
    // alert('hello')
  }
  handleDrawerToggle = (e) => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  handleCourseList = (e) => {
    this.setState({
      title: "Course List",
      currentView: "CourseList",
      showAddView: false,
      addBtnText: "",
    });
    this.handleDrawerToggle();
  };

  handleSettings = (e) => {
    this.setState({
      title: "Settings",
      currentView: "Settings",
      showAddView: false,
      addBtnText: "",
    });
    this.handleDrawerToggle();
  };

  goToCreateInputPage = (courseId, courseName) => {
    if (localStorage.getItem("user_type") === "1") {
      this.setState({
        title: courseName,
        currentView: "TeacherMainPage",
        showAddView: false,
        selectedCouse: courseId,
        addBtnText: "",
      });
    } else {
      this.setState({
        title: courseName,
        currentView: "CreateInput",
        showAddView: false,
        selectedCouse: courseId,
        addBtnText: "",
      });
    }
  };

  handleLogout = () => {
    localStorage.clear();
    // localStorage.setItem('i18nextLng','de')
    
    window.open("#", "_self");
    setTimeout(()=>{
      this.props.i18n.changeLanguage('de');
    },100)
    
  };

  changeView = (t,i18n) => {
    switch (this.state.currentView) {
      case "CourseList":
        return <CourseList courseClick={this.goToCreateInputPage} t={t} i18n={i18n} />;
      case "CreateInput":
        return <CreateInput courseId={this.state.selectedCouse} t={t} i18n={i18n} />;
      case "Settings":
        return <Settings t={t} i18n={i18n} />;
      case "TeacherMainPage":
        return <TeacherMainPage courseId={this.state.selectedCouse} title={this.state.title} t={t} i18n={i18n}/>;
      default:
        return <CourseList t={t} i18n={i18n}/>;
    }
  };

  render() {
    const {t,i18n} = this.props;
    const drawer = (
      <div className="fullHeightWidthCls">
        <div />
        <Divider />
        <List>
          <ListItem>{t('Welcome '+localStorage.getItem("first_name")+'')}</ListItem>
        </List>

        <List>
          <Divider />
          <div className="anchoreBlack" onClick={this.handleCourseList}>
            <ListItem button>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t("Course List")} />
            </ListItem>
          </div>
          <Divider />
          <div
            className="anchoreBlack"
            onClick={this.handleSettings}
            style={{
              display: localStorage.getItem("user_type") === "1" ? "" : "none",
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t("Settings")} />
            </ListItem>
          </div>
          <Divider
            style={{
              display: localStorage.getItem("user_type") === "1" ? "" : "none",
            }}
          />
          <div className="anchoreBlack" onClick={this.handleLogout}>
            <ListItem button>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={t("Logout")} />
            </ListItem>
          </div>
          <Divider />
        </List>
      </div>
    );

    return (
      <div className="fullHeightWidthCls">
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              className="textCenterCls titleStyle"
              noWrap
            >
              {t(this.state.title)}
            </Typography>
            {this.state.addBtnText !== "" ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                btn-name={t(this.state.addBtnText)}
                onClick={this.handleAddBtn}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <IconButton color="primary" edge="end">
                <AddIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <nav aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              open={this.state.isMenuOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className="mainCls">
          <div />
          {this.changeView(t,i18n)}
        </main>
      </div>
    );
  }
}

export default LeftMenu;
