import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../App";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import jQuery from 'jquery';
import Editor from "./Editor";


//import CKEditor from "@ckeditor/ckeditor5-build-classic/node_modules/@ckeditor/ckeditor5-react";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
//import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
//import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
//import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
//import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
//import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
//import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
//import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
//import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
//import Heading from "@ckeditor/ckeditor5-heading/src/heading";
//import Image from "@ckeditor/ckeditor5-image/src/image";
//import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
//import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
//import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
//import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
//import Link from "@ckeditor/ckeditor5-link/src/link";
//import List from "@ckeditor/ckeditor5-list/src/list";
//import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
//import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment"; // <--- ADDED

// const editorConfiguration = {
//   //plugins: [Alignment], //, Essentials, Bold, Italic, Paragraph],
//   toolbar: [
//     "heading",
//     "|",
//     "bold",
//     "italic",
//     "link",
//     "bulletedList",
//     "numberedList",
//     "blockQuote",
//   ],
//   alignment: { options: ["left", "right", "center", "justify"] },
// };

// Plugins to include in the build.
//ClassicEditor.builtinPlugins = [
//Essentials,
//UploadAdapter,
//Autoformat,
//Bold,
//Italic,
//BlockQuote,
//EasyImage,
//Heading,
//Image,
//ImageCaption,
//ImageStyle,
//ImageToolbar,
//ImageUpload,
//Link,
//List,
//Paragraph,
//Alignment, // <--- ADDED
//];

class CreateInput extends Component {
  state = {
    inputVal: "",
    errorTextInputVal: "",
    showErrorInputVal: false,
    ckeditor: "",
    hintText: "Enter your text in above box",
  };

  showHint = (hintVal) => {
    this.setState({
      hintText: hintVal,
    });
    setTimeout(() => {
      this.setState({
        hintText: "",
      });
    }, 3000);
  };

  handleSaveBtn = () => {
    console.log("save btn clicked");
    console.log(this.state);

    if (this.state.inputVal === "") {
      this.showHint("Input field can't be blank. Please put some input.");
      console.log(this.state);
    }

    if (this.state.inputVal === "") return;
    let authData = {
      user_id: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      student_id: localStorage.getItem("user_id"),
      input_text: this.state.inputVal,
      student_name:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),
      course_id: this.props.courseId,
    };

    axios
      .post(apiUrl + "authapis.php?action=studentsubmitinput", authData)
      .then((response) => {
        if (response.data.message) {
          this.showHint(response.data.message);
        }

        if (response.data.statusText !== "success") {
          swal("Oops!", response.data.message, "error");
          if (response.data.statusText === "unauthorised") {
            window.open("#", "_self");
          }
        } else {
          this.setState({inputVal:''});
          swal("Saved!", response.data.message, "success");
        }
      })
      .catch((error) => {
        swal("Oops!", "Something went wrong.", "error");
      });
  };

  render() {
    return (
      <Grid item xs={12}>
        <div style={{ marginTop: "6em" }}>
          <Box mt={2}>
            <Container maxWidth="sm" className="studentCKEditorCls">
              <Editor
                  config={{height:300}}
                  data={this.state.inputVal}
                  onChange={({editor}) => this.setState({inputVal: editor.getData()})}/>
              <div className="hintTextCls">{this.state.hintText}</div>
              <Grid container className="marginTop2Em" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  className="marginAutoItem"
                  onClick={this.handleSaveBtn}
                >
                  Save
                </Button>
              </Grid>
            </Container>
          </Box>
        </div>
      </Grid>
    );
  }
}

export default CreateInput;
