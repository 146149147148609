import React, { Component } from "react";
import axios from "axios";
import { apiUrl } from "../App";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import swal from "sweetalert";
import Editor from "./Editor";
import $ from "jquery";
import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";

class TeacherMainPage extends Component {
  state = {
    courseId: this.props.courseId,
    inputList: [],
    leftSidePan: "",
    openConfirmDelete: false,
    isApiCalled:false,
    board: {
      columns: [
        {
          id: 0,
          title: this.props.t("Content Submission"),
          cards: []
        },
        {
          id: 1,
          title: this.props.t("Technical Terms and Expressions"),
          cards: []
        },
        {
          id: 2,
          title: this.props.t("Domain-Unspecific Educational Vocabulary"),
          cards: []
        }
      ]
    },
    studentName: "",
    studentDescription: "",
    studentId: "",
    columnId: 0,
    openEditCard: false,
    showStudent: 0,
    key: 0
  };

  componentDidMount = () => {
    let authData = {
      user_id: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      course_id: this.props.courseId
    };

    axios
      .post(apiUrl + "authapis.php?action=getsubmittedinputofcourse", authData)
      .then(response => {
        if (response.data.statusText !== "success") {
          swal(this.props.t("Oops!"), this.props.t(response.data.message), "error");
          if (response.data.statusText === "unauthorised") {
            window.open("#", "_self");
          }
        } else {
          let inputListArr = [];
          let leftPanInput = "";
          const board = this.state.board;
          response.data.data.forEach((data, index) => {
            let obj = {
              key: data.id,
              inputText: data.input_text,
              studentName: data.student_name,
              studentId: data.student_id,
              position: data.position,
              title: data.student_name,
              description: data.input_text,
              id: index,
              columnId: +data.position > 2 ? 2 : +data.position
            };
            board.columns[+data.position > 2 ? 2 : +data.position].cards.push(
              obj
            );
            const showStudent = !!parseInt(
              localStorage.getItem("show_student"),
              10
            );
            this.setState({ showStudent: showStudent,isApiCalled:true });
          });

          // board.columns[0].cards = inputListArr;

          this.setState({
            inputList: inputListArr,
            leftSidePan: leftPanInput,
            board: board
          });
        }
      })
      .catch(error => {
        console.error(error);
        swal(this.props.t("Oops!"), this.props.t("Something went wrong."), "error");
      });
  };
  editCard = (id, name, description, columnId) => {
    this.setState({
      studentName: name,
      studentDescription: description,
      studentId: id,
      columnId: columnId
    });
    this.setState({ openEditCard: true });
  };
  closeModel = () => {
    this.setState({ openEditCard: false });
  };
  saveQuote = () => {
    let { board, columnId } = this.state;
    board.columns[columnId].cards.forEach(data => {
      if (data.key === this.state.studentId) {
        data.description = this.state.studentDescription;
      }
    });
    this.setState({ board: board, openEditCard: false });
  };
  handleCardMove = (_card, source, destination) => {
    let { board } = this.state;
    _card.isMoved = true;
    _card.columnId = destination.toColumnId;
    if (!board.columns[destination.toColumnId].cards[destination.toPosition]) {
      board.columns[destination.toColumnId].cards[
        destination.toPosition
      ] = _card;
    } else {
      board.columns[destination.toColumnId].cards.splice(
        destination.toPosition,
        0,
        ...[_card]
      );
    }
    board.columns[source.fromColumnId].cards.splice(source.fromPosition, 1);
    this.setState({ board: board });
  };
  saveCard = (columnId, key) => {
    let { board } = this.state;
    let cardData = {};
    board.columns[columnId].cards.forEach(data => {
      if (data.key === key) {
        cardData = data;
        data.isMoved = false;
      }
    });
    let obj = {
      id: +key,
      user_id: +localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      input_text: cardData.description,
      teacher_id: +localStorage.getItem("user_id"),
      position: columnId
    };
    axios
      .post(apiUrl + "authapis.php?action=studentsaveinput", obj)
      .then(response => {
        if (response.data.statusText !== "success") {
          swal(this.props.t("Oops!"), this.props.t(response.data.message), "error");
          if (response.data.statusText === "unauthorised") {
            window.open("#", "_self");
          }
        } else {
          this.setState({ board: board });
        }
      });
  };
  deleteConfirm(columnId, key, description) {
    this.setState({
      columnId: columnId,
      key: key,
      studentDescription: description,
      openConfirmDelete: true
    });
  }
  export() {
    let { board } = this.state;
    let middle_box_data = "";
    let right_box_data = "";
    board.columns[1].cards.forEach(data => {
      if (data.inputText.replace(/ /g, "")) {
        middle_box_data = middle_box_data + data.inputText + "/r/n";
      }
    });
    board.columns[2].cards.forEach(data => {
      if (data.inputText.replace(/ /g, "")) {
        right_box_data = right_box_data + data.inputText + "/r/n";
      }
    });
    if (middle_box_data || right_box_data) {
      let user_id = +localStorage.getItem("user_id");
      let obj = {
        token: localStorage.getItem("token"),
        user_id: user_id,
        teacher_id: user_id,
        teacher_name:
          localStorage.getItem("first_name") +
          " " +
          localStorage.getItem("last_name"),
        subject_id: this.props.courseId,
        subject_name: this.props.title,
        middle_panel_text: middle_box_data,
        right_panel_text: right_box_data
      };
      axios
        .post(apiUrl + "authapis.php?action=htmlcreate", obj)
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.statusText !== "success"
          ) {
            swal(this.props.t("Oops!"), this.props.t(response.data.message), "error");
            if (response.data.statusText === "unauthorised") {
              window.open("#", "_self");
            }
          } else {
            swal(this.props.t("Success"), this.props.t("Data exported"), "success");
          }
        });
    }else{
      swal(this.props.t("Oops!"), this.props.t("No data available for Export"), "error");   
    }
  }
  deleteCard = isDelete => {
    if (isDelete) {
      let { board } = this.state;
      let cardData = {};
      let obj = {
        id: +this.state.key,
        user_id: +localStorage.getItem("user_id"),
        token: localStorage.getItem("token"),
        input_text: this.state.studentDescription,
        teacher_id: +localStorage.getItem("user_id"),
        position: this.state.columnId
      };
      axios
        .post(apiUrl + "authapis.php?action=deletesentence", obj)
        .then(response => {
          if (response.data.statusText !== "success") {
            swal(this.props.t("Oops!"), this.props.t(response.data.message), "error");
            if (response.data.statusText === "unauthorised") {
              window.open("#", "_self");
            }
          } else {
            let cardIndex = 0;
            board.columns[this.state.columnId].cards.forEach((data, index) => {
              if (data.key === this.state.key) {
                cardIndex = index;
              }
            });
            board.columns[this.state.columnId].cards.splice(cardIndex, 1);
            this.setState({ board: board, openConfirmDelete: false });
          }
        });
    } else {
      this.setState({ openConfirmDelete: false });
    }
  };
  render() {
    function createMarkup(data) {
      return {
        __html: data
      };
    }
    const {t} = this.props;
    return (
      <div>
        <Grid item xs={12} className="teacherContainer">
          <div className="teacherContainer">
            <div className="export-btn">
              <Button  disabled={!this.state.isApiCalled}
                variant="contained"
                onClick={() => this.export()}
                color="primary"
              >
                {t("Export")}
              </Button>
            </div>
            <div>
              <Board
                disableColumnDrag
                onCardDragEnd={this.handleCardMove}
                renderCard={(
                  { key, studentName, description, columnId, isMoved },
                  { removeCard, dragging }
                ) => (
                  <Card
                    onDoubleClick={() =>
                      this.editCard(key, studentName, description, columnId)
                    }
                  >
                    <CardContent>
                      {this.state.showStudent && (
                        // <Typography gutterBottom variant="h5" component="h6">
                        <h5>{studentName}</h5>
                        // </Typography>
                      )}
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={createMarkup(description)}
                        color="textSecondary"
                        component="p"
                      ></Typography>
                    </CardContent>
                    <CardActions>
                      {isMoved && (
                        <Button
                          color="primary"
                          onClick={() => this.saveCard(columnId, key)}
                        >
                          {t("Save")}
                        </Button>
                      )}
                      <Button
                        color="primary"
                        onClick={() =>
                          this.deleteConfirm(columnId, key, description)
                        }
                      >
                        {t("Delete")}
                      </Button>
                    </CardActions>
                  </Card>
                )}
              >
                {this.state.board}
              </Board>
            </div>
          </div>
        </Grid>

        <Dialog
          aria-labelledby="simple-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openEditCard}
        >
          <DialogTitle id="simple-dialog-title">
            {this.state.studentName}
            <IconButton
              aria-label="close"
              className="pull-right"
              onClick={() => this.closeModel()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Editor
              config={{ height: 300 }}
              data={this.state.studentDescription}
              onChange={({ editor }) =>
                this.setState({ studentDescription: editor.getData() })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => this.saveQuote()} color="primary">
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("Delete Quote")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {t("Are you sure you want to delete ?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.deleteCard(true)}>
            {t("Yes")}
            </Button>
            <Button
              color="primary"
              onClick={() => this.deleteCard(false)}
              autoFocus
            >
              {t("No")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TeacherMainPage;
