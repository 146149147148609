import React, {Component} from "react";
import CKEditor from 'ckeditor4-react';

const Editor = ({config, ...props}) => {
    return <CKEditor
        config={{
            removePlugins: 'elementspath',
            resize_enabled: false,
            allowedContent: true,
            toolbar: [{
                items: ['Format', '-', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '-', 'Outdent', 'Indent', '-',
                    'Blockquote', '-', 'Undo', 'Redo']
            }],
            ...config
        }}
        {...props}/>
}

export default Editor;
